import orderBy from 'lodash/orderBy'

import { IFormFieldSections } from '../../features/dynamicForm/DynamicForm.interfaces'
import {
    buildListOfKeyValues,
    ColumnActionConfiguration,
} from '../../utils/functions.utils'
import { CountryCultures } from '../../utils/localization/culture.utils'
import { CountryCode, CustomerDTO } from '../../utils/types/types'

enum SignatureReceiverType {
    Signer = 0,
    Copy = 1,
}

enum SignatureDigitalTypeEnum {
    Digital = 0,
    Touch = 1,
}

export interface IInvoice {
    receiver: SignatureReceiverType
    digitalType: SignatureDigitalTypeEnum
    email: string
    name: string
}

export function CUSTOMER_FORM(
    customer: Partial<CustomerDTO>,
    culture: CountryCode,
    readOnly?: boolean
): IFormFieldSections<Partial<CustomerDTO>> {
    return {
        basicInformation: {
            title: 'Basic Information',
            columns: 2,
            fields: [
                {
                    value: customer.customerNumber,
                    slug: 'customerNumber',
                    label: 'customerNumber',
                    isOptional: true,
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.name,
                    slug: 'name',
                    label: 'name',
                    inputProps: {
                        type: 'text',
                        disabled: readOnly,
                        required: true,
                    },
                },
                {
                    value: customer.cvr,
                    slug: 'cvr',
                    label: 'customerCvr',
                    inputProps: { type: 'text', disabled: readOnly },
                },
            ],
        },
        contactDetails: {
            title: 'Contact Details',
            columns: 2,
            fields: [
                {
                    value: customer.email,
                    slug: 'email',
                    label: 'email',
                    ...(!readOnly ? { leftAddonText: '@' } : {}),
                    inputProps: {
                        type: 'email',
                        disabled: readOnly,
                        required: true,
                    },
                },
                {
                    value: customer.phone,
                    slug: 'phone',
                    label: 'phone',
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.invoicingEmail,
                    slug: 'invoicingEmail',
                    label: 'invoicingEmail',
                    ...(!readOnly ? { leftAddonText: '@' } : {}),
                    inputProps: { type: 'email', disabled: readOnly },
                },
                {
                    value: customer.contactName,
                    slug: 'contactName',
                    label: 'contactName',
                    inputProps: { type: 'text', disabled: readOnly },
                },
            ],
        },
        address: {
            title: 'Address',
            columns: 2,
            fields: [
                {
                    value: customer.street,
                    slug: 'street',
                    label: 'address',
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.building,
                    slug: 'building',
                    label: 'building',
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.floor,
                    slug: 'floor',
                    label: 'floor',
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.postalCode,
                    slug: 'postalCode',
                    label: 'postalCode',
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.city,
                    slug: 'city',
                    label: 'city',
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.country,
                    slug: 'country',
                    label: 'country',
                    inputProps: { type: 'select', disabled: readOnly },
                    skipTranslation: true,
                    optionsKeysValues: buildListOfKeyValues<typeof CountryCode>(
                        CountryCode
                    ).map((item) => {
                        const regionNames = new Intl.DisplayNames(
                            CountryCultures[culture],
                            { type: 'region' }
                        )
                        return [
                            item[0],
                            regionNames.of(item[1].toLocaleUpperCase()),
                        ]
                    }),
                },
            ],
        },
        externalReferences: {
            title: 'External References',
            columns: 2,
            fields: [
                {
                    value: customer.externalReferenceString1,
                    slug: 'externalReferenceString1',
                    label: 'externalReferenceString1',
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.externalReferenceString2,
                    slug: 'externalReferenceString2',
                    label: 'externalReferenceString2',
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.externalReferenceString3,
                    slug: 'externalReferenceString3',
                    label: 'externalReferenceString3',
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.externalReferenceString4,
                    slug: 'externalReferenceString4',
                    label: 'externalReferenceString4',
                    inputProps: { type: 'text', disabled: readOnly },
                },
                {
                    value: customer.externalReferenceText1,
                    slug: 'externalReferenceText1',
                    label: 'externalReferenceText1',
                    inputProps: { type: 'text', disabled: readOnly },
                },
            ],
        },
    }
}

export const getCustomerSignersColumns = (
    translate: Function,
    actionsFn: Function
) => [
    {
        field: 'receiver',
        filter: 'agTextColumnFilter',
        headerName: translate('signatureReceiver'),
    },
    {
        field: 'digitalType',
        filter: 'agTextColumnFilter',
        headerName: translate('digitalType'),
    },
    {
        field: 'email',
        filter: 'agTextColumnFilter',
        headerName: translate('signerEmail'),
    },
    {
        field: 'name',
        filter: 'agTextColumnFilter',
        headerName: translate('signerName'),
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => actionsFn(params),
            100
        ),
    },
]

export const getCustomerSignersRows = (invoices: IInvoice[]): any[] =>
    orderBy(invoices, 'contractNumber', 'desc')?.map((elem) => ({
        receiver: SignatureReceiverType[elem?.receiver],
        digitalType: SignatureDigitalTypeEnum[elem?.digitalType],
        email: elem?.email,
        name: elem?.name,
        item: elem,
    }))
