import { MutableRefObject, ReactElement, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    Flex,
    Heading,
    TabPanel,
    TabPanels,
} from '@chakra-ui/react'

import GenericBox from '../../../components/genericBox/genericBox'
import PageContainer from '../../../components/pageContainer/PageContainer.component'
import TabPanelContainer from '../../../components/tabPanelContainer/TabPanelContainer.component'
import DynamicForm from '../../../features/dynamicForm/DynamicForm.feature'
import ErrorBoundaryWrapper from '../../../features/errorBoundary/ErrorBoundary.feature'
import FileUpload from '../../../features/fileUpload/FileUpload.component'
import { useLocationsContext } from '../../../services/contexts/LocationServices.context'
import { usePartner } from '../../../services/contexts/partner.context'
import { useColorContext } from '../../../services/hooks/color.hook'
import { colorToNumber } from '../../../utils/color.utils'
import { NumberSerieDTO, PartnerDetailsDTO } from '../../../utils/types/types'
import NumberSeriesTableComponent from './NumberSeries/NumberSeriesTable'
import PartnerDetailFormConfig from './Partner.page.config'

export default function PartnerDetailsPage(): ReactElement {
    const {
        activePartner,
        partnerDetails,
        getPartnerDetails,
        updatePartnerDetails,
        updatePartnerLogo,
        updateDocumentPartnerLogo,
    } = usePartner()
    const { locations } = useLocationsContext()
    const translate = useTranslation().t

    useEffect(() => {
        getPartnerDetails()
    }, [])

    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()

    const { primary, secondary, definePrimary, defineSecondary } =
        useColorContext()

    const updateDetails = (data: PartnerDetailsDTO) => {
        const { numberSeries, ...withoutSeries } = data
        updatePartnerDetails(
            activePartner?.isDemo
                ? { numberSeries, ...withoutSeries }
                : ({ numberSeries: [], ...withoutSeries } as any)
        )
    }

    return (
        <PageContainer
            tabsList={[
                {
                    id: 0,
                    text: 'information',
                },
                {
                    id: 1,
                    text: 'customisation',
                },
                {
                    id: 2,
                    text: 'fileManager',
                },
            ]}
        >
            {partnerDetails && (
                <TabPanels>
                    <TabPanel p={0}>
                        <TabPanelContainer>
                            <ErrorBoundaryWrapper id="partner-page-info">
                                <GenericBox maxW={'4xl'} p={8} mb={20}>
                                    <DynamicForm<PartnerDetailsDTO>
                                        data={partnerDetails}
                                        columns={1}
                                        formFieldsWithSections={PartnerDetailFormConfig(
                                            partnerDetails,
                                            locations
                                        )}
                                        hideSubmit={true}
                                        formSubmitReference={
                                            formSubmitReference
                                        }
                                        onSubmit={(
                                            data: PartnerDetailsDTO
                                        ): void => {
                                            const copy = { ...data }
                                            delete copy.logoDocumentId
                                            updateDetails(copy)
                                        }}
                                    ></DynamicForm>
                                    <Button
                                        mt={4}
                                        w={'100%'}
                                        onClick={(): void => {
                                            if (formSubmitReference.current) {
                                                formSubmitReference.current.click()
                                            }
                                        }}
                                    >
                                        {translate('submit')}
                                    </Button>
                                </GenericBox>
                            </ErrorBoundaryWrapper>
                        </TabPanelContainer>
                    </TabPanel>
                    <TabPanel>
                        <TabPanelContainer>
                            <Flex gap={8} alignItems={'flex-start'}>
                                <Box w={'50%'}>
                                    <Heading size="md" pb={4}>
                                        {translate('brandColor')}
                                    </Heading>
                                    <GenericBox p={4}>
                                        <Flex
                                            flexDirection={'column'}
                                            gap={4}
                                            w={'100%'}
                                        >
                                            <Box>
                                                <Flex
                                                    gap={2}
                                                    alignItems={'center'}
                                                    justifyContent={
                                                        'space-between'
                                                    }
                                                >
                                                    <Box>
                                                        <input
                                                            key={1}
                                                            type="color"
                                                            value={primary}
                                                            onChange={(
                                                                e
                                                            ): void => {
                                                                definePrimary(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }}
                                                        />
                                                        <input
                                                            key={2}
                                                            type="color"
                                                            value={secondary}
                                                            onChange={(
                                                                e
                                                            ): void => {
                                                                defineSecondary(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }}
                                                        />
                                                    </Box>

                                                    <Button
                                                        onClick={() => {
                                                            updateDetails({
                                                                ...partnerDetails,
                                                                logoDocumentId:
                                                                    undefined,
                                                                ...(primary
                                                                    ? {
                                                                          lightModeColor:
                                                                              colorToNumber(
                                                                                  primary
                                                                              ),
                                                                      }
                                                                    : {}),
                                                                ...(secondary
                                                                    ? {
                                                                          darkModeColor:
                                                                              colorToNumber(
                                                                                  secondary
                                                                              ),
                                                                      }
                                                                    : {}),
                                                            })
                                                        }}
                                                    >
                                                        {translate('update')}
                                                    </Button>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </GenericBox>
                                </Box>

                                <Flex flexWrap={'wrap'} gap={4} w={'100%'}>
                                    <Box flex={1}>
                                        <Heading size="md" mb={4}>
                                            {translate('partnerLogo')}
                                        </Heading>
                                        <FileUpload
                                            showPreview={true}
                                            callToActionText="selectLogo"
                                            isFormData={true}
                                            handleSubmission={(
                                                data: any
                                            ): void => {
                                                updatePartnerLogo(data)
                                            }}
                                        ></FileUpload>
                                        <Heading
                                            as={'h4'}
                                            fontSize={'md'}
                                            mt={2}
                                        >
                                            {`${translate(
                                                'currentLogoDocumentId'
                                            )}: ${
                                                partnerDetails.uiLogoDocumentId
                                            }`}
                                        </Heading>
                                    </Box>
                                    <Box flex={1}>
                                        <Heading size="md" mb={4}>
                                            {translate('documentLogo')}
                                        </Heading>
                                        <FileUpload
                                            showPreview={true}
                                            callToActionText="selectLogo"
                                            isFormData={true}
                                            handleSubmission={(
                                                data: any
                                            ): void => {
                                                updateDocumentPartnerLogo(data)
                                            }}
                                        ></FileUpload>
                                        <Heading
                                            as={'h4'}
                                            fontSize={'md'}
                                            mt={2}
                                        >
                                            {`${translate(
                                                'currentLogoDocumentId'
                                            )}: ${
                                                partnerDetails.logoDocumentId
                                            }`}
                                        </Heading>
                                    </Box>
                                </Flex>
                            </Flex>
                        </TabPanelContainer>
                    </TabPanel>
                    <TabPanel>
                        <TabPanelContainer>
                            <Flex flexDirection={'column'} gap={4} w="100%">
                                <Heading size="md" mb={4}>
                                    {translate('numberSeries')}
                                </Heading>

                                <NumberSeriesTableComponent
                                    series={partnerDetails.numberSeries}
                                    onSeriesUpdate={(
                                        data: NumberSerieDTO[]
                                    ) => {
                                        const copy = {
                                            ...partnerDetails,
                                            numberSeries: data,
                                        }
                                        updateDetails(copy)
                                    }}
                                ></NumberSeriesTableComponent>
                            </Flex>
                        </TabPanelContainer>
                    </TabPanel>
                </TabPanels>
            )}
        </PageContainer>
    )
}
