/* eslint-disable consistent-return */
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Flex, Stack, useToast } from '@chakra-ui/react'

import GenericBox from '../../../../components/genericBox/genericBox'
import PageSkeleton from '../../../../components/skeleton/page/page.skeleton.component'
import { useUser } from '../../../../services/contexts/user.context'
import { useContractService } from '../../../../services/contract/Contract.services'
import { CustomerContextProvider } from '../../../../services/customer/Customer.services'
import { baseErrorToastOptions } from '../../../../utils/functions.utils'
import { createCustomer } from '../../../../utils/offer/offer.utils'
import { ContractStatus, CustomerDTO } from '../../../../utils/types/types'
import CustomerDetailsInfo from '../../../customerDetails/components/info/CustomerDetailsInfo.component'
import CustomerChangeComponent from '../customer/Customer.component'
import CustomerLocationComponent from '../location/CustomerLocation.component'
import CustomerInfoLocations from './CustomerInfoLocations.component'

function CustomerInfoComponent(): ReactElement {
    const [isChangeCustomerModalOpen, setChangeCustomerModalOpen] =
        useState<boolean>(false)
    const [isChangeLocationModalOpen, setChangeLocationModalOpen] =
        useState<boolean>(false)
    const { user } = useUser()
    const translate = useTranslation().t

    const { contract, updateContract } = useContractService()
    const toast = useToast()
    const navigate = useNavigate()

    const handleCustomerChange = async (
        customer: CustomerDTO
    ): Promise<void | string> => {
        setChangeCustomerModalOpen(false)
        let updateCustomerItem: CustomerDTO = customer
        if (!customer?.customerNumber) {
            const request = await createCustomer(updateCustomerItem, user)
            if (!request.isOk) {
                return toast(baseErrorToastOptions(request.message)) as string
            }
            updateCustomerItem = request.data
        }
        updateContract({
            ...contract,
            customerName: updateCustomerItem.name,
            customerCvr: updateCustomerItem.cvr,
            customerNumber: updateCustomerItem.customerNumber,
        })
    }

    if (!contract?.customerNumber) {
        return <PageSkeleton />
    }

    return (
        <Box>
            <Stack spacing={4}>
                <Flex align="center" justifyContent="flex-end" gap={4}>
                    <Button
                        onClick={(): void => {
                            navigate(`/customers/${contract?.customerNumber}`)
                        }}
                    >
                        {translate('modifyCustomer')}
                    </Button>
                    {!contract?.isLocked &&
                        contract?.status !== ContractStatus.Active &&
                        contract?.status !== ContractStatus.Closed && (
                            <Button
                                onClick={(): void => {
                                    setChangeCustomerModalOpen(true)
                                }}
                            >
                                {translate('changeCustomer')}
                            </Button>
                        )}
                    <Button
                        onClick={(): void => {
                            setChangeLocationModalOpen(true)
                        }}
                    >
                        {translate('addLocation')}
                    </Button>
                </Flex>
            </Stack>
            <Flex gap={6} flexDirection={'column'} my={8}>
                <CustomerDetailsInfo
                    parentCustomerNumber={contract?.customerNumber}
                    readOnly={true}
                />
                <GenericBox rounded="lg">
                    <CustomerInfoLocations />
                </GenericBox>
            </Flex>
            <CustomerChangeComponent
                isModalOpen={isChangeCustomerModalOpen}
                onClose={(): void => setChangeCustomerModalOpen(false)}
                modalTitle={translate('changeCustomer')}
                handleCustomerChange={handleCustomerChange}
            />
            <CustomerLocationComponent
                isModalOpen={isChangeLocationModalOpen}
                onClose={(): void => setChangeLocationModalOpen(false)}
                modalTitle={translate('addLocation')}
                customerNumber={contract?.customerNumber}
            />
        </Box>
    )
}

const CustomerInfoComponentWithContext = (): ReactElement => (
    <CustomerContextProvider>
        <CustomerInfoComponent />
    </CustomerContextProvider>
)
export default CustomerInfoComponentWithContext
