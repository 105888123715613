import { InvoiceDTO, InvoiceLineDTO } from '../../../utils/types/types'

export const sumDepreciation = (invoice: InvoiceDTO) => {
    let sum = 0
    if (!invoice.lines && typeof invoice.lines !== 'object') return sum
    invoice.lines.forEach((inv: InvoiceLineDTO) => {
        sum += inv.depreciation
    })
    return sum.toFixed(2)
}

export const sumInterest = (invoice: InvoiceDTO) => {
    let sum = 0
    if (!invoice.lines && typeof invoice.lines !== 'object') return sum
    invoice.lines.forEach((inv: InvoiceLineDTO) => {
        sum += inv.interest
    })
    return sum.toFixed(2)
}

export const transientFeesColumnsConfig = (
    translate: Function,
    formatValue: Function,
    hasDiscount: boolean
) => [
    {
        headerName: translate('description'),
        field: 'text',
        headerCheckboxSelection: true,
        checkboxSelection: true,
    },
    {
        headerName: translate('amount'),
        field: 'value',
        valueFormatter: (params: any) => formatValue(params, 'value'),
    },
    {
        headerName: translate('vatPercentage'),
        field: 'vatPercentage',
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) => `${params.data.vatPercentage}%`,
    },
    { headerName: translate('isConsumed'), field: 'isConsumed' },
    ...(hasDiscount
        ? [
              {
                  headerName: translate('discount'),
                  field: 'discount',
                  filter: 'agTextColumnFilter',
                  valueGetter: (params: any) =>
                      params.data?.discount?.value
                          ? `${params.data.discount.value}%`
                          : '',
              },
          ]
        : []),
]
